import axios from 'axios';
import { getJwt, getRefreshToken, removeTokens, setTokens } from './jwt';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config) => {
  const { headers } = config;

  const jwt = getJwt();
  if (jwt) {
    headers.Authorization = `Bearer ${jwt}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => Promise.resolve(response),
  async (error) => {
    const { config } = error;

    if (config.url !== '/login' && error.response) {
      // eslint-disable-next-line no-underscore-dangle
      if (error.response.status === 401 && !config._isRefreshingToken) {
        // eslint-disable-next-line no-underscore-dangle
        config._isRefreshingToken = true;

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/refresh-token`,
            {
              refresh_token: getRefreshToken(),
            }
          );

          const { token: jwt, refresh_token: refreshToken } = response.data;
          setTokens({ jwt, refreshToken });

          return api(config);
        } catch (e: any) {
          removeTokens();

          // window.location.href = window.location.origin;
          alert('Ir para a página de login');
          window.location.reload();
          return Promise.reject(e);
        }
      }
    }

    return Promise.reject(error);
  }
);

export default {
  get: api.get.bind(api),
  post: api.post.bind(api),
  put: api.put.bind(api),
  patch: api.patch.bind(api),
  delete: api.delete.bind(api),
};
