import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { ReactComponent as Logo } from 'assets/logo_light.svg';
import { useAuth } from '../../contexts/auth';

import styles from './Login.module.scss';

export default function Login() {
  const { login } = useAuth();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values: any) => {
    setIsLoading(true);
    await login(values.email, values.password);
    setIsLoading(false);
  };

  return (
    <div className={styles.formWrapper}>
      <Form form={form} onFinish={onFinish} className={styles.form}>
        <header className={styles.header}>
          <Logo className={styles.LeoLogo} />
        </header>
        <Form.Item name='email' rules={[{ type: 'email' }]}>
          <Input placeholder='E-mail' />
        </Form.Item>
        <Form.Item name='password'>
          <Input.Password placeholder='Senha' />
        </Form.Item>

        <Form.Item style={{ textAlign: 'end' }}>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Entrar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
