import React, { ReactNode } from 'react';
import { Button, Layout, Menu, theme, Typography } from 'antd';

import { ReactComponent as Logo } from 'assets/logo_light.svg';
import { Link } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import styles from './AppLayout.module.scss';
import { useAuth } from '../../contexts/auth';

const { Text } = Typography;

const { Header, Content } = Layout;

export default function AppLayout({ children }: { children: ReactNode }) {
  const { user, isAdmin, logout } = useAuth();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const items = [
    {
      key: 'users',
      label: <Link to='/users'>Usuários</Link>,
    },
  ];
  if (isAdmin()) {
    items.push({
      key: 'ai-credits-usage',
      label: <Link to='/ai-credits-usage'>Créditos IA</Link>,
    });
  }

  return (
    <Layout className={styles.Layout}>
      <Header className={styles.Header}>
        <Link to='/' className={styles.HomepageLink}>
          <Logo className={styles.LeoLogo} />
        </Link>
        <Menu
          theme='dark'
          mode='horizontal'
          items={items}
          className={styles.Menu}
        />
        <div>
          <Text style={{ color: 'white' }}>{user.name}</Text>
          <Button
            type='primary'
            danger
            onClick={() => {
              logout();
            }}
            style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
            size='small'
          >
            <LogoutOutlined />
          </Button>
        </div>
      </Header>
      <Content
        className={styles.Content}
        style={{
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        {children}
      </Content>
    </Layout>
  );
}
