import React, { useEffect, useState } from 'react';
import {
  Button,
  message,
  Popconfirm,
  Table,
  TableColumnsType,
  Tag,
  Typography,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';

const { Text } = Typography;

type UserType = {
  id: number;
  key: number;
  name: string;
  loginToken: string;
  email: string;
  isActive: boolean;
  trialEndsAt: string;
  createdAt: string;
  lastLoginAt: string;
  localizedTrialEndsAt: string;
  localizedCreatedAt: string;
  localizedLastLoginAt: string;
};

export default function UsersIndexPage() {
  const { isAdmin } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([] as UserType[]);

  function handleApiError(e: any) {
    message.error(e.message, 3);
    if (
      e.isAxiosError &&
      e.response.status === 422 &&
      !!e.response.data.errors
    ) {
      message.error(
        <div style={{ textAlign: 'left' }}>
          <pre>{JSON.stringify(e.response.data.errors, null, 2)}</pre>
        </div>,
        5
      );
    }
  }

  async function getUsers() {
    if (!isAdmin()) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await api.get('users');
      const { data } = response.data;

      setUsers(
        data.map((item: any) => ({
          ...item,
          key: item.id,
          loginToken: item.loginToken || '',
          trialEndsAt: item.trialEndsAt || '',
          createdAt: item.createdAt || '',
          lastLoginAt: item.lastLoginAt || '',
          localizedTrialEndsAt: item.trialEndsAt
            ? new Date(item.trialEndsAt)
                .toLocaleString('pt-BR')
                .substring(0, 10)
            : '',
          localizedCreatedAt: item.createdAt
            ? new Date(item.createdAt).toLocaleString('pt-BR').replace(',', '')
            : '',
          localizedLastLoginAt: item.lastLoginAt
            ? new Date(item.lastLoginAt)
                .toLocaleString('pt-BR')
                .replace(',', '')
            : '',
        }))
      );
    } catch (e: any) {
      handleApiError(e);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const cherryPickUser = (user: UserType): UserType[] =>
    users.map((u: UserType) => (u.id === user.id ? user : u));

  const generateLoginToken = async (user: UserType) => {
    setIsLoading(true);
    try {
      const response = await api.post(`/users/${user.id}/generate-login-token`);
      const { loginToken } = response.data;

      const newUsers = cherryPickUser({ ...user, loginToken });

      setUsers(newUsers);
      message.success(`Token de ${user.name} gerado com sucesso!`);
    } catch (e) {
      handleApiError(e);
    }
    setIsLoading(false);
  };

  const removeLoginToken = async (user: UserType) => {
    setIsLoading(true);
    try {
      await api.delete(`/users/${user.id}/remove-login-token`);

      const newUsers = cherryPickUser({ ...user, loginToken: '' });

      setUsers(newUsers);
      message.info(`Token de ${user.name} removido com sucesso!`);
    } catch (e) {
      handleApiError(e);
    }
    setIsLoading(false);
  };

  const columns: TableColumnsType<UserType> = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: UserType, b: UserType) => (a.id > b.id ? 1 : -1),
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: (a: UserType, b: UserType) => a.name.localeCompare(b.name),
      key: 'name',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      sorter: (a: UserType, b: UserType) => a.email.localeCompare(b.email),
      key: 'email',
    },
    {
      title: 'Token de login',
      dataIndex: 'loginToken',
      key: 'loginToken',
      sorter: (a: UserType, b: UserType) =>
        a.loginToken.localeCompare(b.loginToken),
      render: (text, record) =>
        text?.length > 0 ? (
          <>
            <Text
              copyable
              style={{ fontFamily: 'monospace', fontSize: 'small' }}
            >
              {text}
            </Text>
            <Popconfirm
              title={`Remover o token de login de ${record.name}?`}
              description={
                <div>
                  <p>
                    Deseja realmente remover o token de login deste usuário?
                    <br />
                    {record.name} ainda poderá efetuar o login com e-mail senha
                    pessoal, caso possua.
                  </p>
                  <p>
                    <strong>E-mail</strong>: {record.email}
                  </p>
                </div>
              }
              onConfirm={() => removeLoginToken(record)}
              okText='Sim, remover'
              okButtonProps={{ danger: true }}
              cancelText='Não'
            >
              <Button
                size='small'
                danger
                type='link'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </>
        ) : (
          <Button
            onClick={() => {
              generateLoginToken(record);
            }}
            size='small'
          >
            Gerar
          </Button>
        ),
    },
    {
      title: 'Ativo?',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) =>
        isActive ? <Tag color='green'>Sim</Tag> : <Tag>Não</Tag>,
    },
    {
      title: 'Trial',
      dataIndex: 'localizedTrialEndsAt',
      key: 'localizedTrialEndsAt',
      sorter: (a: UserType, b: UserType) =>
        a.trialEndsAt.localeCompare(b.trialEndsAt),
      render: (value: string) =>
        value.length > 0 ? <span>Até {value}</span> : <span>Não</span>,
    },
    {
      title: 'Criado em',
      dataIndex: 'localizedCreatedAt',
      key: 'localizedCreatedAt',
      sorter: (a: UserType, b: UserType) =>
        a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: 'Último acesso',
      dataIndex: 'localizedLastLoginAt',
      key: 'localizedLastLoginAt',
      sorter: (a: UserType, b: UserType) =>
        a.lastLoginAt.localeCompare(b.lastLoginAt),
    },
  ];

  return isAdmin() ? (
    <div>
      <h1>Usuários</h1>
      <Table
        loading={isLoading}
        size='small'
        dataSource={users}
        columns={columns}
        pagination={{
          defaultPageSize: 100,
        }}
      />
    </div>
  ) : (
    <div />
  );
}
