import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import api from '../../services/api';

const { Text } = Typography;

type AiCreditMetadataProps = {
  uuid?: string | null;
};

type ItemType = {
  uuid: string;
  userName: string;
  userEmail: string;
  metadata: string | null;
  debug: string | null;
  createdAt: string;
};

export default function AiCreditDetails({ uuid }: AiCreditMetadataProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({} as ItemType);

  async function getItem() {
    setIsLoading(true);
    if (uuid) {
      const response = await api.get(`ai-credits/${uuid}`);
      setData({
        ...response.data,
        createdAt: new Date(response.data.createdAt).toLocaleString('pt-BR'),
        metadata: response.data.metadata
          ? JSON.stringify(response.data.metadata, null, 2)
          : null,
        debug: response.data.debug
          ? JSON.stringify(response.data.debug, null, 2)
          : null,
      });
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getItem();
  }, [uuid]);

  return (
    <>
      <h1 style={{ marginBottom: 0 }}>Detalhes</h1>
      <span style={{ fontFamily: 'monospace', fontWeight: 'bold' }}>
        {uuid}
      </span>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <span>Buscando dados...</span>
      ) : uuid ? (
        <div style={{ marginTop: '1rem' }}>
          <strong>Data</strong>: {data.createdAt}
          <br />
          <strong>Nome</strong>: {data.userName}
          <br />
          <strong>E-mail</strong>: <Text copyable>{data.userEmail}</Text>
          <br />
          <strong>Usage</strong>:&nbsp;
          {data.metadata ? (
            <>
              <Text copyable={{ text: data.metadata }} />
              <br />
              <div style={{ backgroundColor: '#F5F5F5' }}>
                <pre style={{ margin: 0, padding: '0.25rem' }}>
                  {data.metadata}
                </pre>
              </div>
            </>
          ) : (
            <i>Sem informações adicionais</i>
          )}
          <strong>debug</strong>:&nbsp;
          {data.debug ? (
            <>
              <Text copyable={{ text: data.debug }} />
              <br />
              <div
                style={{
                  backgroundColor: '#F5F5F5',
                  overflow: 'auto',
                  fontSize: 'x-small',
                }}
              >
                <pre style={{ margin: 0, padding: '0.25rem' }}>
                  {data.debug}
                </pre>
              </div>
            </>
          ) : (
            <i>Sem informações de debug</i>
          )}
        </div>
      ) : (
        <span>Nenhum registro selecionado</span>
      )}
    </>
  );
}
