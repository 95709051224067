import React from 'react';

import { Route, Routes } from 'react-router-dom';

import Homepage from './pages/Homepage/Homepage';
import NoMatch from './components/NoMatch';
import AiCreditUsage from './pages/AiCreditUsage/AiCreditUsage';
import RequireAuth from './components/RequireAuth';
import LoginPage from './pages/Login/LoginPage';
import UsersIndexPage from './pages/Users/UsersIndexPage';

export default function AppRoutes() {
  return (
    <Routes>
      <Route path='/login' element={<LoginPage />} />

      <Route path='/' element={<RequireAuth />}>
        <Route path='/' element={<Homepage />} />
        <Route path='/ai-credits-usage' element={<AiCreditUsage />} />
        <Route path='/users'>
          <Route index element={<UsersIndexPage />} />
        </Route>
        <Route path='*' element={<NoMatch />} />
      </Route>

      <Route path='*' element={<NoMatch />} />
    </Routes>
  );
}
