import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import Login from '../../components/Login/Login';

export default function LoginPage() {
  const auth = useAuth();

  return auth.user ? <Navigate to='/' replace /> : <Login />;
}
