import React, { createContext, ReactNode, useContext, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { getJwt, removeTokens, setTokens } from '../services/jwt';
import api from '../services/api';

interface AuthContextType {
  user: any;
  login: (username: string, password: string) => void;
  logout: () => void;
  isAdmin: () => boolean;
}

export type UserType = {
  name: string;
  email: string;
  roles: string[];
};

const AuthContext = createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: ReactNode }) {
  const retrieveUserFromToken = (token: string) => {
    return jwtDecode<UserType>(token);
  };

  const retrieveUserFromStorage = (): UserType | null => {
    const jwt = getJwt();

    return jwt ? retrieveUserFromToken(jwt) : null;
  };

  const [user, setUser] = useState<UserType | null>(retrieveUserFromStorage());

  const login = async (email: string, password: string) => {
    try {
      const response = await api.post('/login', {
        email,
        password,
      });
      const { token: jwt, refresh_token: refreshToken } = response.data;
      setTokens({ jwt, refreshToken });
      setUser(retrieveUserFromToken(jwt));
    } catch (e: any) {
      throw new Error(
        e?.response?.status === 401
          ? 'E-mail ou senha incorretos'
          : 'Tente novamente mais tarde ou entre em contato'
      );
    }
  };

  const logout = () => {
    setUser(null);
    removeTokens();
  };

  const isAdmin = () => {
    return user?.roles?.some((role: string) => role === 'ROLE_ADMIN') || false;
  };

  const value: AuthContextType = {
    user,
    login,
    logout,
    isAdmin,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// eslint-disable-next-line import/prefer-default-export
export const useAuth = () => useContext(AuthContext);
