import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/auth';
import AppLayout from './AppLayout/AppLayout';

export default function RequireAuth() {
  const auth = useAuth();
  const location = useLocation();

  return auth.user ? (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
}
