// eslint-disable-next-line no-underscore-dangle
function _setToken(key: 'jwt' | 'refreshToken', value: string) {
  localStorage.setItem(key, value);
}

// eslint-disable-next-line no-underscore-dangle
function _getToken(key: 'jwt' | 'refreshToken'): string | null {
  return localStorage.getItem(key);
}

// eslint-disable-next-line no-underscore-dangle
function _removeToken(key: 'jwt' | 'refreshToken') {
  localStorage.removeItem(key);
}

function setJwt(jwt: string) {
  _setToken('jwt', jwt);
}

function getJwt(): string | null {
  return _getToken('jwt');
}

function removeJwt(): void {
  _removeToken('jwt');
}

function setRefreshToken(refreshToken: string) {
  _setToken('refreshToken', refreshToken);
}

function getRefreshToken(): string | null {
  return _getToken('refreshToken');
}

function removeRefreshToken(): void {
  _removeToken('refreshToken');
}

function setTokens({
  jwt,
  refreshToken,
}: {
  jwt: string;
  refreshToken: string;
}) {
  setJwt(jwt);
  setRefreshToken(refreshToken);
}

function getTokens() {
  return {
    jwt: getJwt(),
    refreshToken: getRefreshToken(),
  };
}

function removeTokens() {
  removeJwt();
  removeRefreshToken();
}

export {
  setJwt,
  getJwt,
  removeJwt,
  setRefreshToken,
  getRefreshToken,
  removeRefreshToken,
  setTokens,
  getTokens,
  removeTokens,
};
